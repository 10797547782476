import React from "react";
import '../styles.css';

function BoardsPanel(){
    return(
        <div className='container' style={{display: 'flex', alignItems:'center', flexDirection:'column'}}>
        
        <div style={{display:'flex', flexDirection:'column',border:'1px solid black', padding:'8px', width:'800px', marginTop:'16px'}}>
        <h3 style={{alignSelf:'center'}}>Boards</h3>
        <div className='boardsContainer' style={{display:'grid', gridTemplateColumns: 'auto auto auto auto'}}>
            <div>
                <ul style={{listStyle:'none'}}>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/a' style={{textDecoration:'none', color: '#2B2D42'}}>Anime</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/c' style={{textDecoration:'none', color: '#2B2D42'}}>Cute</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/w' style={{textDecoration:'none', color: '#2B2D42'}}>Wallpapers</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/csp' style={{textDecoration:'none', color: '#2B2D42'}}>Cosplay</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/n' style={{textDecoration:'none', color: '#2B2D42'}}>Transportation</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/o' style={{textDecoration:'none', color: '#2B2D42'}}>Otaku</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/vg' style={{textDecoration:'none', color: '#2B2D42'}}>Video Games</a></li>
                </ul>
            </div>

            <div>
                <ul style={{listStyle:'none'}}>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/co' style={{textDecoration:'none', color: '#2B2D42'}}>Comics</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/t' style={{textDecoration:'none', color: '#2B2D42'}}>Technology</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/tv' style={{textDecoration:'none', color: '#2B2D42'}}>Television and Movies</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/k' style={{textDecoration:'none', color: '#2B2D42'}}>Weapons</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/at' style={{textDecoration:'none', color: '#2B2D42'}}>Auto</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/na' style={{textDecoration:'none', color: '#2B2D42'}}>Nature</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/tg' style={{textDecoration:'none', color: '#2B2D42'}}>Traditional Games</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/sp' style={{textDecoration:'none', color: '#2B2D42'}}>Sports</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/wr' style={{textDecoration:'none', color: '#2B2D42'}}>Professional Wrestling</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/st' style={{textDecoration:'none', color: '#2B2D42'}}>STEM</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/h' style={{textDecoration:'none', color: '#2B2D42'}}>Humanities</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/i' style={{textDecoration:'none', color: '#2B2D42'}}>International</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/od' style={{textDecoration:'none', color: '#2B2D42'}}>Outdoors</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/toy' style={{textDecoration:'none', color: '#2B2D42'}}>Toys</a></li>       
                </ul>
            </div>

            <div>
                <ul style={{listStyle:'none'}}>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/ok' style={{textDecoration:'none', color: '#2B2D42'}}>Oekaki</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/po' style={{textDecoration:'none', color: '#2B2D42'}}>Papercraft and Origami</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/p' style={{textDecoration:'none', color: '#2B2D42'}}>Photography</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/fc' style={{textDecoration:'none', color: '#2B2D42'}}>Food and Cooking</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/art' style={{textDecoration:'none', color: '#2B2D42'}}>Artwork</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/lit' style={{textDecoration:'none', color: '#2B2D42'}}>Literature</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/mu' style={{textDecoration:'none', color: '#2B2D42'}}>Music</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/f' style={{textDecoration:'none', color: '#2B2D42'}}>Fashion</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/gd' style={{textDecoration:'none', color: '#2B2D42'}}>Graphic Design</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/diy' style={{textDecoration:'none', color: '#2B2D42'}}>DIY</a></li>
       
                </ul>
            </div>

            <div>
                <ul style={{listStyle:'none'}}>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/bf' style={{textDecoration:'none', color: '#2B2D42'}}>Business and Finance</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/tr' style={{textDecoration:'none', color: '#2B2D42'}}>Travel</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/fit' style={{textDecoration:'none', color: '#2B2D42'}}>Fitness</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/para' style={{textDecoration:'none', color: '#2B2D42'}}>Paranormal</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/adv' style={{textDecoration:'none', color: '#2B2D42'}}>Advice</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/lgbtq' style={{textDecoration:'none', color: '#2B2D42'}}>LGBTQ</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/pony' style={{textDecoration:'none', color: '#2B2D42'}}>Pony</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/news' style={{textDecoration:'none', color: '#2B2D42'}}>News</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/b' style={{textDecoration:'none', color: '#2B2D42'}}>Random</a></li>
                    <li style={{ padding:'0 0 8px 0'}}><a href='/pas' style={{textDecoration:'none', color: '#2B2D42'}}>Polls and Surveys</a></li>       
                </ul>
            </div>

        </div>
        </div>

        </div>

    );
}

export default BoardsPanel;