import React, {useState} from "react"; 
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Snackbar } from "@mui/material";
import axios from '../axios';
import { storage } from '../firebase';
import { uploadBytesResumable, ref, getDownloadURL } from "firebase/storage";


function PopUpPost(props){
    const [open, setOpen] = useState(false);
    const [text, setText] = useState('');
    const [postImage, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);   
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleReport = async () => {

      if(props.replyID != null){
        const response = await axios({
          method: 'post',
          url: `/api/report/${props.replyID}`,
  
        });
  
        if(response){
          alert('Success');
        }
      }else{
        const response = await axios({
          method: 'post',
          url: `/api/report/${props.postID}`,
  
        });
  
        if(response){
          alert('Success');
        }
      }
    }

    const handleSubmit = async () => {
      // upload image then post
      if(text.trim().length < 1){
          alert('You need to say something');
          return;
      }
  
      if(text.trim().length > 10000){
          alert('That text is too long!');
          return;
      }

      if(!postImage){
        const response = await axios({
          method: 'post',
          url: `/api/create/${props.board}`,
          data: {
              image_url: null,
              postText: text,
              board: props.board,
              reply_to: props.postID,
              op: props.replyID
          }
      });
      if(response){
          setLoading(false);
          setOpen(false);
          setSnackbar(true);
          return;
      }
      }
  
       setLoading(true);
  
       const storageRef = ref(storage, postImage.name);
       const uploadTask = uploadBytesResumable(storageRef, postImage);
  
       uploadTask.on(
          "state_changed",
          (snapshot) => {
            const prog = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(prog);
          },
          (error) => console.log(error),
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then( async (downloadURL) => {
              const response = await axios({
                  method: 'post',
                  url: `/api/create/${props.board}`,
                  data: {
                      image_url: downloadURL,
                      postText: text,
                      board: props.board,
                      reply_to: props.postID,
                      op: props.replyID
                  }
              });
              if(response){
                  setLoading(false);
                  setOpen(false);
                  setSnackbar(true);
              }
            });
          }
        );     
      }
    const handlePostImageUpload = (e) => {
      if (e.target.files[0]) {
          setImage(e.target.files[0]);
      }
  }

  const handleText = (e) => {
    setText(e.target.value);
  }

  const handleCloseSnackbar = () => {
    setSnackbar(false);
  }

    return(
        <div>
        <Button variant='text' onClick={handleClickOpen} style={{color: 'black'}}>
          Reply
        </Button>
        <Button variant='text' onClick={handleReport} style={{color:'black'}}>
            Report
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Reply</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Leave a comment here"
              type="text"
              multiline
              fullWidth
              variant="standard"
              onChange={handleText}
              inputProps={{maxLength: 10000}}
            />
          </DialogContent>
          <DialogActions>
          <input type='file' accept='image/*' id='myfile' name='myfile' onChange={handlePostImageUpload} style={{margin:'8px'}}/>
            <Button onClick={handleClose} style={{color: 'black'}}>Cancel</Button>
            {loading ? <div></div> : <Button onClick={handleSubmit} style={{color: 'black'}}>Post</Button>}
          </DialogActions>
        </Dialog>
        <Snackbar message='Success' open={snackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}></Snackbar>
      </div>
    );
}

export default PopUpPost;