import React, {useState} from "react";
import axios from '../axios';
import { storage } from '../firebase';
import { uploadBytesResumable, ref, getDownloadURL } from "firebase/storage";

function CreateThread(props){
    const [text, setText] = useState('');
    const [postImage, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handlePostImageUpload = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }else{
            alert('You need to attach an image.')
        }
    }

    const handleSubmit = async () => {
    // upload image then post
    if(!postImage){
        alert('You need to attach an image');
        return;
    }
    if(text.trim().length < 1){
        alert('You need to say something');
        return;
    }

    if(text.trim().length > 10000){
        alert('That text is too long!');
        return;
    }

     setLoading(true);

     const storageRef = ref(storage, postImage.name);
     const uploadTask = uploadBytesResumable(storageRef, postImage);

     uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log(prog);
        },
        (error) => console.log(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then( async (downloadURL) => {
            const response = await axios({
                method: 'post',
                url: `/api/create/${props.board}`,
                data: {
                    image_url: downloadURL,
                    postText: text,
                    board: props.board,
                    reply_to: null
                }
            });
            if(response){
                setLoading(false);
                window.location.href = `/${props.board}/${response.data}`; 
            }
          });
        }
      );     
    }


    const handleText = (e) => {
        setText(e.target.value);
    }

    return(
        <div style={{display:'flex', marginTop:'16px', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <h3>/{props.board}/ - {props.boardTitle}</h3>
            <h4>Create a new thread</h4>
            <textarea style={{whiteSpace:'pre-wrap'}} rows={5} cols={40} placeholder={'Leave a comment here'} maxLength={1000} minLength={1} onChange={handleText}></textarea>
            <div style={{display:'flex', flexDirection:'row'}}>
                <label for='myfile' style={{margin:'8px'}}>Attach image</label>
                <input type='file' accept='image/*' id='myfile' name='myfile' onChange={handlePostImageUpload} style={{margin:'8px'}}/>
            </div>
            {loading ? <div></div> : <input type='submit' value='Post' onClick={handleSubmit}/>} 
        </div>
    );
}

export default CreateThread;