import BoardsPanel from './BoardsPanel';
import Header from './Header';
import Recent from './Recent';
import '../styles.css';

function Home() {
  return (
    <div>
      <div className='container'>
        <Header></Header>
        <BoardsPanel></BoardsPanel>
        <Recent></Recent>
        <footer style={{position:'fixed', bottom:'0', marginLeft:'8px'}}>
          <div style={{display:'flex', flexDirection:'row'}}>
            <p style={{marginRight: '8px'}}>&copy; Mimefly Inc. All rights reserved.</p>
            <p onClick={() => {window.location.href = '/contact'}}>Contact</p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Home;