import React from "react";

function Rules(){
    return(
        <div style={{display:'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
            <h3>Safechan is meant to be a safe and inclusive environment, which means:</h3>
            <ul>
                <li>No racism.</li>
                <li>No sexism.</li>
                <li>No homophobia.</li>
                <li>No transphobia.</li>
                <li>No adult content.</li>
            </ul>
            <h3>Your post will be deleted if you break any of these simple rules.</h3>
        </div>
    );
}

export default Rules;