import React, {useState} from "react";
import '../styles.css';
import PopUpPost from "./PopUpPost";
import moment from 'moment';

function MainPost(props){

    const [popUp, setPopUp] = useState(false);

    const handleClick = (e) => {
        setPopUp(!popUp);
    }

    const time = moment(props.dateCreated).fromNow();  

    return(
        <div style={{margin:'16px'}}>
            <hr></hr>
            <div className='postContainer' style={{display:'flex', flexDirection:'row'}}>
            {popUp ? <PopUpPost replyID={props.replyID}  postID={props.postID}  board={props.board}></PopUpPost> : <div></div>}
                <a href={props.image} rel='noreferrer' target='_blank'><div style={{backgroundImage:`url(${props.image})`, width:'240px', 
                height:'240px', flexShrink:'0',  backgroundSize:'cover', marginRight: '16px', display: props.image == null ? 'none' : 'block'}}>
                </div></a>
                    <p style={{flexShrink:'0'}} ><a href={`${props.board}/${props.postID}`} style={{textDecoration:'underline'}}>{props.replyCount} replies - </a>{time} - <span onClick={handleClick} style={{textDecoration:'underline'}}>No. {props.postID}</span></p>
                    <pre className='postText' style={{padding:'3px 0 0 8px'}}><span style={{fontSize:'16px', fontFamily:'sans-serif', whiteSpace:'pre-wrap'}}>{props.postText}</span></pre>
            </div>
            
            <hr></hr>
            
        </div>

    );
}

export default MainPost;