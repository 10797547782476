import React from 'react';

function Contact(){
    return(
        <div style={{display:'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
        <h3>Contact email: mimeflyqa@gmail.com</h3>
    </div>
    );

}

export default Contact;