import React, {useState, useEffect} from "react";
import CreateThread from "./CreateThread";
import axios from '../axios';
import MainPost from "./MainPost";

function Random(){
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        getPosts();
    },[]);

    const getPosts = async () => {
        const response = await axios({
            method: 'get',
            url: '/api/b',
        });

        if(response){
            setPosts(response.data);
        }
    }

    return(
        <div>
            <CreateThread board={'b'} boardTitle={'Random'}></CreateThread>
            <hr></hr>
            {posts.map(post => {
               return <MainPost board={'b'} replyID={post.id} postID={post.id} postText={post.postText} image={post.image_url} dateCreated={post.dateCreated} replyCount={post.replyCount}></MainPost>
            })}
        </div>
    );
}

export default Random;