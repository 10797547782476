import './App.css';
import Home from './components/Home';
import Rules from './components/Rules';
import Anime from './components/Anime';
import Cute from './components/Cute';
import Cosplay from './components/Cosplay';
import Wallpapers from './components/Wallpapers';
import Transportation from './components/Transportation';
import Otaku from './components/Otaku';
import VideoGames from './components/VideoGames';
import Comics from './components/Comics';
import Technology from './components/Technology';
import TVandMovies from './components/TVandMovies';
import Weapons from './components/Weapons';
import Auto from './components/Auto';
import Nature from './components/Nature';
import TraditionalGames from './components/TraditionalGames';
import Sports from './components/Sports';
import Wrestling from './components/Wrestling';
import STEM from './components/STEM';
import Humanities from './components/Humanities';
import International from './components/International';
import Outdoors from './components/Outdoors';
import Toys from './components/Toys';
import Oekaki from './components/Oekaki';
import Papercraft from './components/Papercraft';
import Photography from './components/Photography';
import Food from './components/Food';
import Artwork from './components/Artwork';
import Literature from './components/Literature';
import Music from './components/Music';
import Fashion from './components/Fashion';
import GraphicDesign from './components/GraphicDesign';
import DIY from './components/DIY';
import Business from './components/Business';
import Travel from './components/Travel';
import Fitness from './components/Fitness';
import Paranormal from './components/Paranormal';
import Advice from './components/Advice';
import LGBTQ from './components/LGBTQ';
import Pony from './components/Pony';
import News from './components/News';
import Random from './components/Random';
import PollsAndSurveys from './components/PollsAndSurverys';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import AnimeThread from './components/AnimeThread';
import CuteThread from './components/CuteThread';
import CosplayThread from './components/CosplayThread';
import WallpapersThread from './components/WallpapersThread';
import TransportationThread from './components/TransportationThread';
import OtakuThread from './components/OtakuThread';
import VideoGamesThread from './components/VideoGamesThread';
import ComicsThread from './components/ComicsThread';
import TechnologyThread from './components/TechnologyThread';
import TVandMoviesThread from './components/TVandMoviesThread';
import WeaponsThread from './components/WeaponsThread';
import AutoThread from './components/AutoThread';
import NatureThread from './components/NatureThread';
import TraditionalGamesThread from './components/TraditionalGamesThread';
import SportsThread from './components/SportsThread';
import WrestlingThread from './components/WrestlingThread';
import STEMThread from './components/STEMThread';
import HumanitiesThread from './components/HumanitiesThread';
import InternationalThread from './components/InternationalThread';
import OutdoorsThread from './components/OutdoorsThread';
import ToysThread from './components/ToysThread';
import OekakiThread from './components/OekakiThread';
import PapercraftThread from './components/PapercraftThread';
import PhotographyThread from './components/PhotographyThread';
import FoodThread from './components/FoodThread';
import ArtworkThread from './components/ArtworkThread';
import LiteratureThread from './components/LiteratureThread';
import MusicThread from './components/MusicThread';
import FashionThread from './components/FashionThread';
import GraphicDesignThread from './components/GraphicDesignThread';
import DIYThread from './components/DIYThread';
import BusinessThread from './components/BusinessThread';
import TravelThread from './components/TravelThread';
import FitnessThread from './components/FitnessThread';
import ParanormalThread from './components/ParanormalThread';
import AdviceThread from './components/AdviceThread';
import LGBTQThread from './components/LGBTQThread';
import PonyThread from './components/PonyThread';
import NewsThread from './components/NewsThread';
import RandomThread from './components/RandomThread';
import PollsAndSurveysThread from './components/PollsAndSurveysThread';
import Contact from './components/Contact';

function App() {
  return (
    <div className="App">
      <div className='container'>
        <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>}>
          </Route>
          <Route path='/rules' element={<Rules/>} ></Route>
          <Route path='/a' element={<Anime/>}></Route>
          <Route path='/a/:id' element={<AnimeThread/>}></Route>
          <Route path='/c' element={<Cute/>}></Route>
          <Route path='/c/:id' element={<CuteThread/>}></Route>
          <Route path='/w' element={<Wallpapers/>}></Route>
          <Route path='/w/:id' element={<WallpapersThread/>}></Route>
          <Route path='/csp' element={<Cosplay/>}></Route>
          <Route path='/csp/:id' element={<CosplayThread/>}></Route>
          <Route path='/n' element={<Transportation/>}></Route>
          <Route path='/n/:id' element={<TransportationThread/>}></Route>
          <Route path='/o' element={<Otaku/>}></Route>
          <Route path='/o/:id' element={<OtakuThread/>}></Route>
          <Route path='/vg' element={<VideoGames/>}></Route>
          <Route path='/vg/:id' element={<VideoGamesThread/>}></Route>
          <Route path='/co' element={<Comics/>}></Route>
          <Route path='/co/:id' element={<ComicsThread/>}></Route>
          <Route path='/t' element={<Technology/>}></Route>
          <Route path='/t/:id' element={<TechnologyThread/>}></Route>
          <Route path='/tv' element={<TVandMovies/>}></Route>
          <Route path='/tv/:id' element={<TVandMoviesThread/>}></Route>
          <Route path='/k' element={<Weapons/>}></Route>
          <Route path='/k/:id' element={<WeaponsThread/>}></Route>
          <Route path='/at' element={<Auto/>}></Route>
          <Route path='/at/:id' element={<AutoThread/>}></Route>
          <Route path='/na' element={<Nature/>}></Route>
          <Route path='/na/:id' element={<NatureThread/>}></Route>
          <Route path='/tg' element={<TraditionalGames/>}></Route>
          <Route path='/tg/:id' element={<TraditionalGamesThread/>}></Route>
          <Route path='/sp' element={<Sports/>}></Route>
          <Route path='/sp/:id' element={<SportsThread/>}></Route>
          <Route path='/wr' element={<Wrestling/>}></Route>
          <Route path='/wr/:id' element={<WrestlingThread/>}></Route>
          <Route path='/st' element={<STEM/>}></Route>
          <Route path='/st/:id' element={<STEMThread/>}></Route>
          <Route path='/h' element={<Humanities/>}></Route>
          <Route path='/h/:id' element={<HumanitiesThread/>}></Route>
          <Route path='/i' element={<International/>}></Route>
          <Route path='/i/:id' element={<InternationalThread/>}></Route>
          <Route path='/od' element={<Outdoors/>}></Route>
          <Route path='/od/:id' element={<OutdoorsThread/>}></Route>
          <Route path='/toy' element={<Toys/>}></Route>
          <Route path='/toy/:id' element={<ToysThread/>}></Route>
          <Route path='/ok' element={<Oekaki/>}></Route>
          <Route path='/ok/:id' element={<OekakiThread/>}></Route>
          <Route path='/po' element={<Papercraft/>}></Route>
          <Route path='/po/:id' element={<PapercraftThread/>}></Route>
          <Route path='/p' element={<Photography/>}></Route>
          <Route path='/p/:id' element={<PhotographyThread/>}></Route>
          <Route path='/fc' element={<Food/>}></Route>
          <Route path='/fc/:id' element={<FoodThread/>}></Route>
          <Route path='/art' element={<Artwork/>}></Route>
          <Route path='/art/:id' element={<ArtworkThread/>}></Route>
          <Route path='/lit' element={<Literature/>}></Route>
          <Route path='/lit/:id' element={<LiteratureThread/>}></Route>
          <Route path='/mu' element={<Music/>}></Route>
          <Route path='/mu/:id' element={<MusicThread/>}></Route>
          <Route path='/f' element={<Fashion/>}></Route>
          <Route path='/f/:id' element={<FashionThread/>}></Route>
          <Route path='/gd' element={<GraphicDesign/>}></Route>
          <Route path='/gd/:id' element={<GraphicDesignThread/>}></Route>
          <Route path='/diy' element={<DIY/>}></Route>
          <Route path='/diy/:id' element={<DIYThread/>}></Route>
          <Route path='/bf' element={<Business/>}></Route>
          <Route path='/bf/:id' element={<BusinessThread/>}></Route>
          <Route path='/tr' element={<Travel/>}></Route>
          <Route path='/tr/:id' element={<TravelThread/>}></Route>
          <Route path='/fit' element={<Fitness/>}></Route>
          <Route path='/fit/:id' element={<FitnessThread/>}></Route>
          <Route path='/para' element={<Paranormal/>}></Route>
          <Route path='/para/:id' element={<ParanormalThread/>}></Route>
          <Route path='/adv' element={<Advice/>}></Route>
          <Route path='/adv/:id' element={<AdviceThread/>}></Route>
          <Route path='/lgbtq' element={<LGBTQ/>}></Route>
          <Route path='/lgbtq/:id' element={<LGBTQThread/>}></Route>
          <Route path='/pony' element={<Pony/>}></Route>
          <Route path='/pony/:id' element={<PonyThread/>}></Route>
          <Route path='/news' element={<News/>}></Route>
          <Route path='/news/:id' element={<NewsThread/>}></Route>
          <Route path='/b' element={<Random/>}></Route>
          <Route path='/b/:id' element={<RandomThread/>}></Route>
          <Route path='/pas' element={<PollsAndSurveys/>}></Route>
          <Route path='/pas/:id' element={<PollsAndSurveysThread/>}></Route>
          <Route path='/contact' element={<Contact/>}></Route>
        </Routes>
        </BrowserRouter>
 
      </div>
    </div>
  );
}

export default App;
