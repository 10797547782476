import React, {useState, useEffect} from "react";
import axios from '../axios';
import {useParams} from 'react-router-dom';
import MainPost from "./MainPost";
import Reply from "./Reply";


function OutdoorsThread(){
    const {id} = useParams();
    const [post, setPost] = useState({});
    const [replies, setReplies] = useState([]);

    const getThread = async () => {
        const response = await axios({
            method: 'get',
            url: `/api/replies/${id}`,
        });

        if(response){
            setPost(response.data.post);
            setReplies(response.data.replies);
        }
    }

    useEffect(() => {
        getThread();
    }, []);
    
    return(
        <div>
            <div style={{display:'flex', marginTop:'16px', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <h3>/od/ - Outdoors</h3>
        </div>
            <MainPost replyID={post.id} board={post.board} postID={post.id} postText={post.postText} image={post.image_url} dateCreated={post.dateCreated} replyCount={post.replyCount}></MainPost>
            {replies.map((reply) => {
                return <Reply key={reply.id} board={reply.board} replyID={reply.id} postID={post.id} postText={reply.postText} image={reply.image_url} dateCreated={reply.dateCreated} replyCount={reply.replyCount}></Reply>
            })}
        </div>
    );
}

export default OutdoorsThread;