import { initializeApp } from "firebase/app";
import { getStorage} from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCs9G45oywwTXM-iKEiwJ95kdxddP67-7U",
    authDomain: "safechan-e9148.firebaseapp.com",
    projectId: "safechan-e9148",
    storageBucket: "safechan-e9148.appspot.com",
    messagingSenderId: "408573044918",
    appId: "1:408573044918:web:c00d264fa9c33ac1559e17",
    measurementId: "G-KV6P7TB872"
  };

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

const analytics = getAnalytics(app);

export { storage, app };