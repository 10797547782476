import React from 'react';
import moment from 'moment';

function RecentPost(props){
    const time = moment(props.dateCreated).fromNow();  

    return(
        <div style={{margin:'16px'}}>
            <div className='postContainer' style={{display:'flex', flexDirection:'row'}}>
                <a href={props.image} rel='noreferrer' target='_blank'><div style={{backgroundImage:`url(${props.image})`, width:'64px', 
                height:'64px', flexShrink:'0',  backgroundSize:'cover', marginRight: '16px'}}>
                </div></a>
                    <p style={{flexShrink:'0'}} ><a href={`${props.board}/${props.postID}`} style={{textDecoration:'underline'}}>{props.replyCount} replies - </a>{time} - <span style={{textDecoration:'underline'}}>No. {props.postID}</span></p>
                    <pre className='postText' style={{padding:'3px 0 0 8px'}}><span style={{fontSize:'16px', fontFamily:'sans-serif', whiteSpace:'pre-wrap'}}>{props.postText}</span></pre>
            </div>          
        </div>

    );
}

export default RecentPost;