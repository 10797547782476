import React from "react";
import '../styles.css';

function Header(){
    return(
        <div className='header' style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
        <div style={{border:'1px solid black', padding:'8px', width:'800px', marginTop:'16px'}}>
            <p>
                What is Safechan?
            </p>
            <p>
                Safechan is an image based board where users can make upload images and make posts. Safechan is
                a safe space where everyone is included. Safechan does not require users to make accounts in order to post. 
                Find your interest below and start posting!
            </p>
            <p>Read our rules here <span><a href='/rules'>Rules</a></span></p>
        </div>
        </div>
    );
}

export default Header;