import React, { useState } from "react";
import PopUpPost from "./PopUpPost";
import '../styles.css';
import moment from 'moment';
import axios from '../axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
const reactStringReplace = require('react-string-replace')


function Reply(props){

    const [popUp, setPopUp] = useState(false);
    const [post, setPost] = useState({});
    const [popUpPost, setPopUpPost] = useState(false);

    const handleClose = () => {
        setPopUpPost(!popUpPost);
    }

    const showReplied = (param) => async () => {
        const response = await axios({
            method: 'get',
            url: `/api/getReply/${param}`,
        });

        if(response){
            setPost(response.data);
            setPopUpPost(!popUpPost);
        }else{
            setPost({});
            setPopUpPost(false);
        }
    }

    const handleClick = () => {
        setPopUp(!popUp);
    }

    const handleHover = (e) => {
        e.target.style.opacity = 0.42;
    }

    const handleMouseLeave = (e) => {
        e.target.style.opacity = 1;
    }

    const time = moment(props.dateCreated).fromNow();
    const postTime = moment(post.dateCreated).fromNow();

    const text = props.postText;
    let replacedText;
    

    replacedText = reactStringReplace(text, />>(\w+)/m, (match, i) => (
        <div onMouseEnter={handleHover} onMouseLeave={handleMouseLeave} ><span style={{color: '#00a8f3'}}>&gt;&gt;</span><a id={match} onClick={showReplied(match)} style={{color: '#00a8f3'}}>{match}</a></div>
      ));

    return(
        <div style={{margin:'16px'}}>
            <div className='postContainer' style={{display:'flex', flexDirection:'row'}}>
            {popUp ? <PopUpPost replyID={props.replyID} postID={props.postID} board={props.board}></PopUpPost> : <div></div>}
            {popUpPost ? <Dialog open={popUpPost} onClose={handleClose}>
          <DialogTitle>Reply</DialogTitle>
          <DialogContent>
          <div className='postContainer' style={{display:'flex', flexDirection:'row'}}>
            <a href={post.image_url} rel='noreferrer' target='_blank'>  <div style={{backgroundImage:`url(${post.image_url})`, width:'240px', 
                height:'240px',flexShrink:'0', backgroundSize:'cover', marginRight: '16px', display: post.image_url == null ? 'none' : 'block'}}>
                </div></a>
                    <p style={{flexShrink:'0', paddingLeft: post.image_url == null ? '16px' : '0px'}}><span style={{textDecoration:'underline'}}>{post.replyCount} replies</span> {postTime} - <span onClick={handleClick} style={{textDecoration:'underline'}}></span></p>
                    <pre className='postText' style={{padding:'3px 0 0 8px'}}><span style={{fontSize:'16px', fontFamily:'sans-serif', whiteSpace:'pre-wrap'}}>{post.postText}</span></pre>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} style={{color: 'black'}}>Close</Button>
          </DialogActions>
        </Dialog> : <div></div>}
            <a href={props.image} rel='noreferrer' target='_blank'>  <div style={{backgroundImage:`url(${props.image})`, width:'240px', 
                height:'240px',flexShrink:'0', backgroundSize:'cover', marginRight: '16px', display: props.image == null ? 'none' : 'block'}}>
                </div></a>
                    <p style={{flexShrink:'0', paddingLeft: props.image == null ? '16px' : '0px'}}><span style={{textDecoration:'underline'}}>{props.replyCount} replies</span> {time} - <span onClick={handleClick} style={{textDecoration:'underline'}}>No. {props.replyID}</span></p>
                    <pre className='postText' style={{padding:'3px 0 0 8px'}}><span style={{fontSize:'16px', fontFamily:'sans-serif', whiteSpace:'pre-wrap'}}>{replacedText}</span></pre>
            </div>
            
            <hr></hr>
            
        </div>
    );
}

export default Reply;