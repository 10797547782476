import axios from '../axios';
import React, {useState, useEffect} from 'react';
import '../styles.css';
import RecentPost from './RecentPost';

function Recent(){

    const [posts, setPosts] = useState([]);

    const getRecent = async () => {
        const response = await axios({
            method: 'put',
            url: '/api/recent',
        });

        if(response){
            setPosts(response.data);
        }
    }

    useEffect(() => {
        getRecent(); 
    }, []);

    return(
        <div style={{display: 'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', border: '1px solid black', margin: '16px auto', width: '800px', padding:'8px'}}>
             <h3>Recent Posts</h3>
            <div className='postContainer' style={{display:'grid', gridTemplateColumns:'auto', gridGap:'8px', padding: '8px'}}>
            {posts.map(reply => {
                return <RecentPost key={reply.id} board={reply.board} replyID={reply.id} postID={reply.id} postText={reply.postText} image={reply.image_url} dateCreated={reply.dateCreated} replyCount={reply.replyCount}></RecentPost>
            })}
            </div>  
        </div>
 
    );
}

export default Recent;